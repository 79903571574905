// Pass backend variables to js variables
/**
 * VARIABLES AND FUNCTIONS THAT NEED INITIATION
 */
let windowSize = window.innerWidth
let breakpoint = 540;
let dynamicImages = true



/**
 * EVENT LISTENERS
 */

// Execute something on 575 breakpoint (mobile to desktop and vice versa)

if (dynamicImages) {
  setBackgrounds();
  window.addEventListener('resize', function(event) {
    if ((windowSize > breakpoint && window.innerWidth <= breakpoint) || (windowSize <= breakpoint && window.innerWidth > breakpoint)) {
      setBackgrounds();
    }
    windowSize = window.innerWidth;
  }, true);

  /**
   * FUNCTIONS
   */

  // Get Sections Data and check if it has a background image
  function setBackgrounds() {
    let images = document.querySelectorAll(".responsive-background-image");
  
    let isMobile = window.innerWidth <= breakpoint;
    
    images.forEach(image => {
      let imageSrc = image.src ? image.src : null;
      
      
      if (imageSrc && imageSrc.includes('stickers')) {
        image.src = isMobile ? addSizeMobile(imageSrc) : removeSizeMobile(imageSrc);
      }
    });
  }
  
  function addSizeMobile(imageUrl) {
    if (!imageUrl.includes('?size=mob-g')) {
      return imageUrl + '?size=mob-g';
    }
    return imageUrl;
  }
  
  function removeSizeMobile(imageUrl) {
    return imageUrl.replace('?size=mob-g', '');
  }
  
}