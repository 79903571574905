
function handleResize() {
    const windowWidth = window.innerWidth;

    const resolutionThreshold = 1199;

    let offCanvasTag = document.querySelector('.offcanvas.offcanvas-start');
    let offCanvasBackdropTag = document.querySelector('.offcanvas-backdrop');

    if (windowWidth > resolutionThreshold) {
        if (offCanvasTag && offCanvasBackdropTag) {
            if (offCanvasTag.classList.contains("show")) {
                offCanvasTag.classList.remove('show');
                offCanvasBackdropTag.classList.remove('show');
                body.style.overflow = '';
            }
        }
    }
}

document.addEventListener("DOMContentLoaded", handleResize);
window.addEventListener('resize', handleResize);

// Tener siempre la altura de la navbar en una custom property

function updateHeaderHeight() {
    const header = document.getElementById('header');
    if (header) {
        const headerHeight = header.getBoundingClientRect().height;
        document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
    }
}

document.addEventListener("DOMContentLoaded", () => {
    setTimeout(updateHeaderHeight, 500); // Adjust the delay (1000 milliseconds = 1 second)
    window.addEventListener('resize', updateHeaderHeight);
});